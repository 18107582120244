import TVGConf from "@tvg/conf";
import axios from "axios";
import { tvgHeaders } from "@tvg/api/capi";

export const getPollerTime = async (): Promise<number> => {
  const url = `${TVGConf().config().service.capi}/configs/balance-polling-time`;
  const requestOptions = {
    method: "get",
    url,
    headers: tvgHeaders(),
    withCredentials: true
  };

  const response = await axios(requestOptions);
  return +response.data.value;
};
