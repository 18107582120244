import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import parseJSONCapiMessage from "@tvg/utils/capiUtils";
import HDR from "@tvg/hdr";
import LHN from "@tvg/lhn";
import Footer from "@tvg/footer";
import { useMediaQuery, breakpoints } from "@tvg/design-system";
import SeoScreens from "@tvg/seo-desktop";
import ToastManager from "@tvg/toast-manager";
import useBannerProps from "@urp/compliance-banner/src/useBannerProps";
import useLoadAngular from "@tvg/app-lazy-loader";
import { InvalidResidentialAddressErrorModal } from "@urp/invalid-residential-address";
import {
  Container,
  Main,
  TVG4Content,
  ContentContainer
} from "./styled-components";
import useMtpUpdate from "../../hooks/useMtpUpdate";

interface Props {
  isHeaderVisible?: boolean;
  isLHNVisible?: boolean;
  showTVG4Content?: boolean;
  hasMobileView?: boolean;
  tvg4Addon?: JSX.Element;
}

const MainLayout: React.FC<Props> = ({
  isHeaderVisible = true,
  isLHNVisible = true,
  showTVG4Content = true,
  hasMobileView = false,
  tvg4Addon = null,
  children
}) => {
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const isDesktopMinXl = useMediaQuery(breakpoints.desktop.min.xl);
  const isDesktopMinXxl = useMediaQuery(breakpoints.desktop.min.xxl);
  const [isLargeContent, setIsLargeContent] = useState(false);
  const isFullPageLayout =
    !isHeaderVisible && !isLHNVisible && !showTVG4Content;
  const allowedStates = Object.keys(
    useSelector((store) =>
      parseJSONCapiMessage(store, "capi.messages.stateSelectorListFdr")
    )
  );
  const isInvalidResidentialAddressModalOpen = useSelector((store) =>
    get(store, "modal.isInvalidResidentialAddressModalOpen")
  );
  const invalidResidentialAddressMessage = useSelector((store) =>
    parseJSONCapiMessage(store, "capi.messages.invalidResidentialAddressError")
  );

  const dispatch = useDispatch();
  // GLOBAL UPDATE FOR RACES WITH LESS THEN 60 minutes
  useMtpUpdate();
  useLoadAngular();

  const bannerPropsValues = useBannerProps();

  return (
    <>
      <Container isFullPageLayout={isFullPageLayout}>
        {isHeaderVisible && <HDR />}
        <div id="modals" />
        <Main
          isFullPageLayout={isFullPageLayout}
          hasFixedBanner={!!bannerPropsValues}
        >
          {isInvalidResidentialAddressModalOpen && (
            <InvalidResidentialAddressErrorModal
              isOpen
              isDesktop={isDesktop}
              dispatch={dispatch}
              allowedStates={allowedStates}
              invalidResidentialAddressMessages={
                invalidResidentialAddressMessage
              }
            />
          )}
          {isLHNVisible &&
            (isDesktopMinXl || typeof window === "undefined") && (
              <LHN
                isInsideHamburgerMenu={false}
                changeCollapsedMediaQuery={!isDesktopMinXxl}
                isHeaderVisible={isHeaderVisible}
                isPositionFixed={true}
                showCollapseButton
                setIsLargeContent={setIsLargeContent}
              />
            )}
          <ContentContainer
            isHeaderVisible={isHeaderVisible}
            isLHNVisible={isLHNVisible}
            isFullPageLayout={isFullPageLayout}
            isLargeContent={isLargeContent}
            hasMinWidth={!hasMobileView}
          >
            <>
              <ToastManager
                isLhnVisible={
                  isLHNVisible &&
                  (isDesktopMinXl || typeof window === "undefined")
                }
              />
              <div>
                {tvg4Addon}
                <TVG4Content
                  id="tvg4"
                  data-qa-label="main-layout-tvg4-content"
                  showTVG4Content={showTVG4Content}
                />
              </div>
              {children}
              <SeoScreens />
              {!isFullPageLayout && isDesktop && <Footer />}
            </>
          </ContentContainer>
        </Main>
      </Container>
    </>
  );
};

export default MainLayout;
