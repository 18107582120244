import React from "react";
import { MessageWrapper } from "./styled-components";
import { type InputMessageProps } from "./types";
import { Paragraph } from "../Typography";
import { Icon } from "../Icon";

export const InputMessage = ({
  message,
  isWarning = false,
  qaLabel = "message",
  ...rest
}: InputMessageProps) =>
  isWarning ? (
    <MessageWrapper {...rest} data-qa-label={qaLabel}>
      <Icon
        name="warning"
        size="s"
        lineColor="--fd-colors-component-data-input-content-error"
        backgroundColor="--fd-colors-component-data-input-background-error"
      />
      <Paragraph
        qaLabel="warning-message"
        color="--fd-colors-component-data-input-content-error"
        margin="0"
        ml="space-2"
      >
        {message}
      </Paragraph>
    </MessageWrapper>
  ) : (
    <Paragraph
      qaLabel={qaLabel}
      color="--fd-colors-content-subtle"
      marginBottom="space-2"
      {...rest}
    >
      {message}
    </Paragraph>
  );
