import { Device } from "@tvg/ts-types/Device";

export type ComponentType =
  | "QuickLinks"
  | "UpcomingRaces"
  | "TopRaces"
  | "TipsAndPicks"
  | "TopPools"
  | "TalentPicks"
  | "TopTracks"
  | "PreviousWinners"
  | "PromosWidget"
  | "FeaturedSection"
  | "SameRaceParlay"
  | "SEOFooter"
  | "SameRaceParlay"
  | "SEOFooter"
  | "QuickPicks";

export enum SectionConfigType {
  LABEL_NAME = "labelName",
  DESCRIPTION = "description",
  TOP = "top",
  MAIN = "main",
  SIDE = "side",
  BOTTOM = "bottom"
}

export enum BRAND {
  FDR = "fdr",
  TVG = "tvg"
}

export type ContentProps = {
  content?: {
    content?: {
      text?: string;
    }[];
  }[];
};

interface PreviewImage {
  filename?: string;
  alt?: string;
}

export interface ComponentProps {
  title: string;
  componentName: ComponentType;
  description?: string;
  expirationDate?: string;
  text?: string;
  device?: Device;
  content?: ContentProps;
  name?: string;
  previewImage?: PreviewImage;
}

export type HomepageSection = ComponentProps[] | string;

export type ComponentFallback = {
  [key in ComponentType]: ComponentProps;
};

export interface HomeStoryblockContentResponse {
  templates: string[];
  componentFallback: ComponentProps[];
  [key: string]:
    | HomepageSection
    | ComponentType[]
    | string
    | undefined
    | string[];
}

export type ComponentMapping = {
  [key in ComponentType]: {
    component: (props: ComponentProps) => JSX.Element | null;
  };
};

export type HomepageTemplate = {
  [section in SectionConfigType]: HomepageSection;
};

export interface HomepageStoryblokConfig {
  templates: string[];
  [templateName: string]: HomepageTemplate | string[] | string | undefined;
}

export interface MobileTemplate {
  labelName: String;
  description?: string;
  main: ComponentProps[];
  bottom: ComponentProps[];
}

export interface HomepageStoryblokMobileConfig {
  templates: string[];
  [templateName: string]: MobileTemplate | string[];
}

export interface Section {
  name: string;
  title: string;
  element: HTMLElement | null;
}

export interface ComponentOrder {
  name: string;
  title?: string;
}
