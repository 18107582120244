import { useDispatch, useSelector } from "react-redux";
import { ToastManager } from "@tvg/design-system/src";
import { addToast, removeToast } from "@tvg/toast-manager/src/store/actions";
import { getToastMessage } from "../store/selectors";

const useConfirmationToast = (isMobile: boolean) => {
  const dispatch = useDispatch();
  const toastMessages = useSelector(getToastMessage);

  const showToast = (isSuccess: boolean) => {
    const toastId = `${Date.now()}-new-toast`;
    const variant = isSuccess ? "success" : "error";
    const message = isSuccess
      ? toastMessages.successMessage
      : toastMessages.errorMessage;
    const { duration } = toastMessages;

    if (isMobile) {
      ToastManager.show({
        message,
        variant,
        duration,
        bottomOffset: 60,
        hasDismissButton: false,
        isFullWidth: false
      });
    } else {
      setTimeout(() => {
        dispatch(
          addToast({
            id: toastId,
            qaLabel: "user-profile-toast-desk",
            isVisible: true,
            autoHide: true,
            message,
            duration,
            variant,
            onHide: () => dispatch(removeToast(toastId))
          })
        );
      }, 500);
    }
  };

  return {
    showToast
  };
};

export default useConfirmationToast;
