import React, { SyntheticEvent, useState } from "react";
import { UnaryFn, NullaryFn } from "@tvg/ts-types/Functional";
import mediator from "@tvg/mediator";
import { GlobalWalletMediatorEventType } from "@tvg/ts-types/Amplitude";
import {
  Container,
  PlaceHolder,
  StyledUnmountClosed
} from "./styled-components";
import { Balance } from "../../types";
import AccountBalances from "../AccountBalances";
import AccountBalancesSummary from "../AccountBalancesSummary";
import AccountDetails from "../AccountDetails";

interface Props {
  username: string;
  avatarURL?: string;
  balancesSummary: Balance[];
  balances: Balance[];
  isBalanceVisible: boolean;
  onVisibilityToggle: UnaryFn<SyntheticEvent<HTMLButtonElement>, void>;
  qaLabel?: string;
  onViewBalanceDetails: NullaryFn<void>;
  onOpenPlayableBalanceModal: NullaryFn<void>;
}

export const AccountBalanceDetails = ({
  username,
  avatarURL,
  balancesSummary,
  balances,
  isBalanceVisible,
  onVisibilityToggle,
  onViewBalanceDetails,
  onOpenPlayableBalanceModal,
  qaLabel = "account-balance-details"
}: Props) => {
  const [isCollapsableOpen, setCollapsable] = useState(false);

  return (
    <Container data-qa-label={qaLabel}>
      <AccountDetails username={username} avatarURL={avatarURL} />

      <AccountBalancesSummary
        isVisible={isBalanceVisible}
        onToggle={onVisibilityToggle}
        balances={balancesSummary}
        setCollapsable={(val: boolean) => {
          mediator.base.dispatch({
            type: GlobalWalletMediatorEventType.BALANCE_EXPANDED,
            payload: {
              isBalanceExpanded: val
            }
          });
          setCollapsable(val);
        }}
        isCollapsableOpen={isCollapsableOpen}
      />

      {isBalanceVisible && (
        <StyledUnmountClosed
          isOpened={isCollapsableOpen}
          data-qa-label={`${qaLabel}-collapse`}
        >
          <PlaceHolder />
          <AccountBalances
            balances={balances}
            onViewBalanceDetails={onViewBalanceDetails}
            onOpenPlayableBalanceModal={onOpenPlayableBalanceModal}
          />
        </StyledUnmountClosed>
      )}
    </Container>
  );
};

export default AccountBalanceDetails;
