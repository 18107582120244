import React, { useCallback, useEffect, useState } from "react";
import {
  Wrapper,
  InformationWrapper,
  ContentWrapper,
  ToastCloseButton,
  LinkWrapper,
  Link,
  TitleWrapper,
  toastAnimationDuration
} from "./styled-components";
import type { ToastProps } from "./types";
import { Icon } from "../Icon";
import { toastContentMap } from "./constants";
import { Paragraph } from "../Typography";

const Toast = ({
  qaLabel = "toast",
  variant = "success",
  title,
  subTitle,
  showToast = false,
  callback,
  duration = 5000,
  offset = 0,
  hasDismissButton,
  hasDismissAnimation = false,
  toastTrigger,
  onDismissPress,
  linkLabel,
  onLinkClick,
  type = "straight",
  ...rest
}: ToastProps) => {
  const [isVisible, setIsVisible] = useState(showToast);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleDismiss = useCallback(
    (e) => {
      onDismissPress?.(e);
      setIsAnimating(false);
      setTimeout(() => setIsVisible(false), toastAnimationDuration);
    },
    [onDismissPress]
  );

  useEffect(() => {
    if (showToast && toastTrigger) {
      setIsVisible(true);
      setIsAnimating(true);
      setTimeout((e) => handleDismiss(e), duration);
    }
  }, [toastTrigger, showToast, handleDismiss]);

  return isVisible && toastTrigger ? (
    <Wrapper
      data-qa-label={qaLabel}
      data-visible={isAnimating}
      visible={isVisible}
      offset={offset}
      hasDismissAnimation={hasDismissAnimation}
      {...rest}
    >
      <ContentWrapper variant={variant} type={type}>
        <Icon
          name={toastContentMap[variant].icon.name}
          lineColor={toastContentMap[variant].icon.lineColor}
          size="s"
          backgroundColor={toastContentMap[variant].icon.backgroundColor}
        />
        <InformationWrapper>
          <TitleWrapper>
            <Paragraph
              fontFamily="bold"
              lineHeight="125%"
              fontSize="14px"
              color={toastContentMap[variant].text}
              whiteSpace="nowrap"
              margin={0}
            >
              {!title ? subTitle : title}
            </Paragraph>

            {((linkLabel && type === "round") ||
              (type === "straight" && (!title || !subTitle))) && (
              <LinkWrapper>
                <Link
                  role="link"
                  data-qa-label="alert-inline-link"
                  onClick={(e) => {
                    onLinkClick?.(e as unknown as MouseEvent);
                  }}
                  color={toastContentMap[variant].link}
                >
                  {linkLabel}
                </Link>
              </LinkWrapper>
            )}
          </TitleWrapper>

          {title && subTitle && (
            <Paragraph
              fontFamily="regular"
              lineHeight="125%"
              fontWeight="400"
              fontSize="14px"
              color={toastContentMap[variant].text}
              margin={0}
              marginBottom={4}
              marginTop={4}
            >
              {subTitle}
            </Paragraph>
          )}

          {linkLabel && type === "straight" && title && subTitle && (
            <LinkWrapper>
              <Link
                role="link"
                data-qa-label="alert-inline-link"
                onClick={(e) => {
                  onLinkClick?.(e as unknown as MouseEvent);
                }}
                color={toastContentMap[variant].link}
              >
                {linkLabel}
              </Link>
            </LinkWrapper>
          )}
        </InformationWrapper>

        {hasDismissButton && (
          <ToastCloseButton
            type={type}
            onClick={handleDismiss}
            data-qa-label="alert-inline-close-button"
          >
            <Icon
              name="close"
              lineColor={toastContentMap[variant].icon.lineColor}
              backgroundColor="transparent"
              size="s"
              qaLabel="toast-icon-close"
            />
          </ToastCloseButton>
        )}
      </ContentWrapper>
    </Wrapper>
  ) : null;
};

export { Toast, type ToastProps };
