import styled, { createGlobalStyle, css } from "styled-components";
import { buildColor } from "@tvg/design-system";

export const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1001;
`;

export const ContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  background-color: var(--fd-colors-racing-background-default);
  box-shadow: 0 2px 4px rgba(17, 43, 68, 0.14);
`;

export const Header = styled.header`
  height: var(--hdr-height, 76px);
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

export const Wrapper = styled.div`
  position: relative;
  height: calc(100% - var(--hdr-height, 76px));
  display: flex;
`;

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const UnorderedList = styled.ul`
  ${flexColumn};
  width: 222px;
`;

export const TitleContainer = styled.li`
  position: sticky;
  top: -1px;
  background-color: var(--fd-colors-racing-background-default);
  padding: 16px;
  z-index: 2;
`;

/* TODO remove this when DS buttons support left alignment */
export const ListItem = styled.li`
  & > div {
    align-items: flex-start;
  }
`;

export const MenuEntryContainer = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  text-align: start;
  background-color: transparent;
  border-radius: 2px;
  color: ${buildColor("blue_accent", "000")};
  cursor: pointer;
  padding: 0 16px;
  margin-bottom: 8px;
  min-height: 44px;
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes && theme.fontSizes.s};
  font-family: ${({ theme }) => theme.fonts && theme.fonts.medium};

  &:hover {
    background-color: ${buildColor("white", "000")};
  }
`;

interface LHNContainerProps {
  isIntermediumMediaQuery: boolean;
}

export const LHNContainer = styled.div<LHNContainerProps>`
  padding: 0 8px;
  ${({ isIntermediumMediaQuery }) =>
    !isIntermediumMediaQuery &&
    css`
      border-left: 1px solid ${buildColor("blue", "800")};
    `}
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  background-color: ${buildColor("black", "700")};
`;
