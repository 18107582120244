import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { set } from "lodash";
import type { IModalPresenter } from "@fanduel/core-modal-presenter-contract";
import { isMobile } from "@tvg/sh-utils/mobileUtils";
import { modalPresenterSubscriptions } from "./utils/completion";
import { webModalPresenterSubscriptions } from "./utils/web";
import { AccountModule, CoreWebviewModule } from "../../../useLoadModule/types";
import { getCoreConfigState } from "../../../../redux/selector/coreConfig";

export const useSubscriptions = (
  modalPresenter: IModalPresenter | null,
  CoreWebView: CoreWebviewModule | null,
  accountInstance: AccountModule | null
) => {
  const [isSubscribing, setIsSubscribing] = useState(false);
  const coreConfigRegion = useSelector(getCoreConfigState);
  const customProps = useRef({});
  const dispatch = useDispatch();

  useEffect(() => {
    set(customProps.current, "region", coreConfigRegion);
  }, [coreConfigRegion]);

  useEffect(() => {
    if (!isSubscribing) {
      if (isMobile()) {
        setIsSubscribing(true);

        modalPresenterSubscriptions(dispatch);
      } else if (modalPresenter && CoreWebView && accountInstance) {
        setIsSubscribing(true);

        modalPresenterSubscriptions(dispatch);
        webModalPresenterSubscriptions(
          modalPresenter,
          CoreWebView,
          accountInstance,
          customProps
        );
      }
    }
  }, [modalPresenter, CoreWebView, accountInstance]);
};
