import React, { useMemo, useState, useCallback } from "react";
// @ts-ignore
import { useNavigate } from "react-router-dom";
import conf from "@tvg/conf";
import { useSelector } from "react-redux";
import { get } from "lodash";
import mediator from "@tvg/mediator";
import {
  Icon,
  IconNames,
  useIsValidIcon,
  breakpoints
} from "@tvg/design-system";
import { LinkCard, Button } from "@tvg/design-system/web";
import { getWagerProfile } from "@urp/store-selectors";
import { getURPEducationHubMessages } from "@urp/education-hub/src/selectors";
import { ButtonIcon } from "@tvg/design-system/src/components/button/types";
import {
  Container,
  ButtonContainer,
  ContentWrapper
} from "./styled-components";
import AnimatedMenu from "./animatedMenu";
import { SubMenus } from "../types";
import { enableURPEducationHub } from "../../store/selectors";
import { parseSubMenus } from "../utils";

export interface DropdownMenuProps {
  title: string;
  route: string;
  target?: string;
  qaLabel: string;
  subMenus: Array<SubMenus>;
  disableClick?: boolean;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  title,
  route,
  target,
  qaLabel,
  subMenus,
  disableClick = false
}) => {
  const isValidIcon = useIsValidIcon();
  const navigate = useNavigate();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const mouseEvents = {
    onMouseEnter: () => setIsMenuVisible(true),
    onMouseLeave: () => setIsMenuVisible(false)
  };
  const userProfile = useSelector(getWagerProfile);
  const isURPEducationHubEnable = useSelector(enableURPEducationHub);
  const urpEducationHubMessages = useSelector(getURPEducationHubMessages);
  const parsedSubMenus = parseSubMenus(subMenus, {
    isURPEducationHubEnable,
    urpEducationHubMessages,
    isDropdownMenu: true
  });
  const buttonIcon = parsedSubMenus.length
    ? { icon: isMenuVisible ? "chevronUp" : "chevronDown" }
    : {};

  const handleNavigation = useCallback(
    (targetBlank, url, linkTitle, isParent = false) => {
      mediator.base.dispatch({
        type: "NAVIGATION:MENU_CLICK",
        payload: {
          action: "Global Navigation Link Clicked",
          link: linkTitle,
          destinationUrl: url,
          menu: title,
          module: "Global Header"
        }
      });
      if (disableClick && isParent) {
        return false;
      }
      return targetBlank ? window.open(url, "_blank") : navigate(url);
    },
    []
  );

  const menuItems = useMemo(
    () =>
      parsedSubMenus
        .filter(
          (subMenu) =>
            !get(subMenu, "options.brandsException", []).includes(conf.brand) &&
            !get(subMenu, "options.profileTypeException", []).includes(
              userProfile
            )
        )
        .map((item: SubMenus, index: number) => {
          const newWindow = item.options && item.options.target === "newWindow";
          const iconName = isValidIcon(item.icon) ? item.icon : "info";
          return (
            <li key={`menu-item-${index.toString()}`}>
              <LinkCard
                title={item.name}
                description={item.description}
                onPress={() =>
                  handleNavigation(newWindow, item.route, item.name)
                }
                alignStart
                startContent={
                  <Icon
                    name={iconName as IconNames}
                    lineColor="blue_accent.500"
                    backgroundColor="transparent"
                    size="s"
                    mr="space-4"
                  />
                }
                qaLabel={item.subQaLabel}
              />
            </li>
          );
        }),
    [parsedSubMenus]
  );

  return (
    <Container mediaQuery={breakpoints.desktop.max.xl}>
      <ButtonContainer
        data-qa-label="dropdown-button-container"
        {...mouseEvents}
      >
        <Button
          variant={isMenuVisible ? "secondaryDark" : "tertiaryDark"}
          onClick={() =>
            handleNavigation(target === "newWindow", route, title, true)
          }
          qaLabel={qaLabel}
          iconPosition="end"
          {...(buttonIcon as { icon: ButtonIcon })}
        >
          {title}
        </Button>
      </ButtonContainer>
      <AnimatedMenu
        isMenuVisible={isMenuVisible}
        mouseEvents={mouseEvents}
        hasContent={!!parsedSubMenus.length}
      >
        <ContentWrapper data-qa-label={`${title}-SubMenuItem`}>
          {menuItems}
        </ContentWrapper>
      </AnimatedMenu>
    </Container>
  );
};

export default DropdownMenu;
