import React from "react";
import type { FilterCellButtonProps } from "./types";
import { Icon } from "../Icon";
import { Paragraph } from "../Typography";
import { FilterCell } from "./styled-components";

export const FilterCellButton = ({
  value,
  size,
  onChange,
  iconName,
  iconSide = "left",
  label,
  isSelected = false,
  isDisabled = false,
  isStretched = false,
  qaLabel = "filter-cell-button",
  ...rest
}: FilterCellButtonProps) => {
  const handleClick = () => {
    onChange?.(value);
  };

  return (
    <FilterCell
      value={value}
      onClick={handleClick}
      aria-selected={isSelected}
      disabled={isDisabled}
      iconSide={iconSide}
      size={size}
      isStretched={isStretched}
      data-qa-label={qaLabel}
      data-qa-active={isSelected ? "select" : ""}
      {...rest}
    >
      {iconName && (
        <Icon
          name={iconName}
          size="s"
          lineColor="--fd-colors-content-default"
          qaLabel=""
        />
      )}
      <Paragraph
        color="--fd-colors-content-default"
        fontSize={size === "s" ? "xs" : "s"}
        lineHeight="125%"
        fontStyle="normal"
        fontFamily="medium"
        fontWeight={500}
      >
        {label}
      </Paragraph>
    </FilterCell>
  );
};

export type { FilterCellButtonProps };
