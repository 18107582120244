import axios, { AxiosError } from "axios";
import { createAuthTokenCookie } from "@tvg/sh-utils/sessionUtils";
import { logout } from "../logout";
import ModulesLoader from "../../../hooks/useLoadModule/modulesLoader";
import { UnifiedModules } from "../../../hooks";

export const validateSessionExpiredAxiosCallback = async (
  error: AxiosError
) => {
  const accountLibrary = await ModulesLoader[UnifiedModules.ACCOUNT]();
  const hasValidSession = await accountLibrary.checkValidSession();

  if (!hasValidSession) {
    logout();
    return Promise.reject(error);
  }

  const session = await accountLibrary.getSessionAsync("Local");
  if (session?.token) {
    createAuthTokenCookie(session.token);
  }

  return axios(error.config);
};
