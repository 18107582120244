import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Store } from "redux";

import { Race } from "@tvg/ts-types/Race";
import { useURPProgramPageOnXSell } from "@urp/amplitude-experiments";
import { getWagerProfile } from "@urp/store-selectors";
import useTodaysTracks from "./useTodaysTracks";
import useTodaysRaces from "./useTodaysRaces";
import { getOptedInPromos, getActiveBets } from "../redux/selectors";
import { FilterOptions } from "../types";
import { getUpcomingRaces, getRacesWithTrackLocation } from "../utils/races";
import { UPCOMING_RACES_STATUS, NO_TRACKS_FILTER } from "../constants";

interface Props {
  maxResults?: number;
  filterOptions?: FilterOptions;
}
export default ({
  maxResults = 20,
  filterOptions = {
    regionsFilter: [],
    raceTypesFilter: [],
    tracksSearchQuery: "",
    distancesFilter: [],
    raceStatusFilter: [],
    trackTypesFilter: []
  }
}: Props) => {
  const [upcomingRaces, setUpcomingRaces] = useState<Array<Race>>([]);
  const [upcomingRacesLoading, setUpcomingRacesLoading] = useState(true);
  const wagerProfile = useSelector(getWagerProfile);
  const promosOptedIn = useSelector(getOptedInPromos);
  const shouldUseUrpPPonXSell = useURPProgramPageOnXSell();
  const activeBets = useSelector((store: Store) =>
    getActiveBets(store, shouldUseUrpPPonXSell)
  );
  const { loading: tracksLoading, tracks } = useTodaysTracks({
    wagerProfile,
    regionsFilter: filterOptions?.regionsFilter,
    searchQuery: filterOptions?.tracksSearchQuery,
    trackTypesFilter: filterOptions.trackTypesFilter
  });
  const { loading: todaysRacesLoading, races: allUpcomingRaces } =
    useTodaysRaces({
      wagerProfile,
      tracksFilter:
        tracks.length > 0
          ? tracks.map((track) => track.code)
          : NO_TRACKS_FILTER,
      raceTypesFilter: filterOptions.raceTypesFilter,
      regionsFilter: filterOptions.regionsFilter,
      distancesFilter: filterOptions.distancesFilter,
      racesFilter: filterOptions.racesFilter,
      raceStatusFilter: UPCOMING_RACES_STATUS,
      uniqByTrack: true
    });

  useEffect(() => {
    if (!tracksLoading && !todaysRacesLoading) {
      const upcomingBaseInfo = getUpcomingRaces(allUpcomingRaces, maxResults);
      const upcomingWithTrackInfo = getRacesWithTrackLocation(
        upcomingBaseInfo,
        tracks
      );
      setUpcomingRaces(upcomingWithTrackInfo);
      setUpcomingRacesLoading(false);
    }
  }, [
    tracksLoading,
    todaysRacesLoading,
    JSON.stringify(allUpcomingRaces),
    JSON.stringify(tracks)
  ]);

  return {
    loading: todaysRacesLoading || tracksLoading || upcomingRacesLoading,
    upcomingRaces,
    promosOptedIn,
    activeBets
  };
};
