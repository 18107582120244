import { RaceProgram } from "@tvg/ts-types/Race";
import type { Actions } from "./actions";
import { BetPromoBannerContent } from "../types";

export type State = {
  favoriteRunners: Array<string>;
  isRunnerModifierExpanded: boolean;
  fixWagerSelector: { isWagerSelectorFixed: boolean; fixedOffset: number };
  isPoolsNInfoModalOpen: boolean;
  freePastPerformances: Record<string, string>[];
  PIRace?: RaceProgram;
  betSlipHeight: number;
  addToBetSlipOriginMobile?: string;
  betPromoBannerContent: BetPromoBannerContent | undefined;
};

export const initialState: State = {
  favoriteRunners: [],
  isRunnerModifierExpanded: false,
  fixWagerSelector: { isWagerSelectorFixed: false, fixedOffset: 0 },
  isPoolsNInfoModalOpen: false,
  freePastPerformances: [],
  PIRace: undefined,
  betSlipHeight: 0,
  betPromoBannerContent: undefined
};

export default function reducer(
  // eslint-disable-next-line
  state: State = initialState,
  action: Actions
): State {
  switch (action.type) {
    case "SET_USER_FAVORITE_RUNNERS":
      return {
        ...state,
        favoriteRunners: action.payload.favoriteRunners
      };
    case "ADD_USER_FAVORITE_RUNNER":
      return {
        ...state,
        favoriteRunners: [...state.favoriteRunners, action.payload.runner]
      };
    case "SET_RUNNER_MODIFIER_EXPANDED":
      return {
        ...state,
        isRunnerModifierExpanded: action.payload
      };
    case "FIX_WAGER_SELECTOR":
      return {
        ...state,
        fixWagerSelector: action.payload
      };
    case "SET_IS_POOLS_AND_INFO_MODAL_OPEN":
      return {
        ...state,
        isPoolsNInfoModalOpen: action.payload.isPoolsNInfoModalOpen,
        PIRace: action.payload.PIRace
      };
    case "SET_FREE_PAST_PERFORMANCES":
      return {
        ...state,
        freePastPerformances: action.payload
      };
    case "RESET_PROGRAM_PAGE_STATE":
      return {
        ...initialState,
        // keep betPromoBannerContent returned from server side
        betPromoBannerContent: state.betPromoBannerContent
      };
    case "SET_BETSLIP_HEIGHT":
      return {
        ...state,
        betSlipHeight: action.payload
      };
    case "SET_ADD_TO_BET_SLIP_ORIGIN_MOBILE":
      return {
        ...state,
        addToBetSlipOriginMobile: action.payload
      };
    case "SET_BET_PROMO_BANNER_CONTENT":
      return {
        ...state,
        betPromoBannerContent: action.payload
      };
    default:
      return state;
  }
}
