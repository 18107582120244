import React, { useEffect, useState } from "react";
import mediator from "@tvg/mediator";
import { Loading } from "@tvg/design-system";
import { useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { useSelector } from "react-redux";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import { getUserCompliance } from "@urp/unified-modules/src/utils/account/getUserCompliance";
import { getAccountNumber } from "@urp/store-selectors";
import { getSession } from "@urp/unified-modules/src/utils/account";
import { isFDR } from "@tvg/utils/generalUtils";

import { LoadingRouteContainer } from "./styled-components";

interface Props {
  protectedRoute: boolean;
  isLogged: boolean;
  loginRequest: boolean;
  intendedPath: string;
  children: JSX.Element;
}

// TODO Refactor this with the new protocol, this is an hack to close the socket and open it again without losing the subscriptions
// @ts-ignore
const clearWebSocketOnLogin = (wsClient) => {
  try {
    // @ts-ignore
    if (wsClient?.close) {
      wsClient.close(false, true);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Fail to clear subscription", e);
  }
};

const RequireAuth = ({
  protectedRoute,
  isLogged,
  loginRequest,
  intendedPath,
  children
}: Props) => {
  const [finishedLogIn, setFinishedLogIn] = useState(false);
  const navigate = useNavigate();
  // navigate back while the user is logged in;
  const navigateBack =
    protectedRoute && loginRequest && !isLogged && finishedLogIn;
  const client = useApolloClient();
  const accountId = useSelector(getAccountNumber);
  const isAccountCompliant = useSelector(isAccountCompliantSelector);

  useEffect(() => {
    if (protectedRoute && loginRequest && !isLogged) {
      const loginSuccessCallback = () => {
        setFinishedLogIn(true);
        navigate(intendedPath);
      };

      if (isFDR()) {
        getSession("Normal", loginSuccessCallback);
      } else {
        mediator.base.dispatch({
          type: "TVG_LOGIN:OPEN_LOGIN_MODAL",
          payload: {
            callback: loginSuccessCallback
          }
        });
      }
    }

    if (isLogged && protectedRoute && !isAccountCompliant) {
      getUserCompliance();
    }
  }, [protectedRoute, loginRequest, isLogged, isAccountCompliant]);

  useEffect(() => {
    if (navigateBack) {
      navigate(-1);
    }
  }, [navigateBack]);

  useEffect(() => {
    if (!isLogged) {
      setFinishedLogIn(false);
    }
  }, [isLogged]);

  useEffect(() => {
    mediator.base.subscribe("AW_SESSION_REFRESHED", () => {
      // @ts-ignore
      if (client?.subscriptionCosmoClient?.terminate) {
        // @ts-ignore
        client?.subscriptionCosmoClient?.terminate();
      } else {
        // @ts-ignore
        clearWebSocketOnLogin(client?.subscriptionClient);
      }
    });
  }, []);

  useEffect(
    () => () => {
      // @ts-ignore
      if (client?.subscriptionCosmoClient?.terminate) {
        // @ts-ignore
        client?.subscriptionCosmoClient?.terminate();
      } else if (!accountId) {
        // @ts-ignore
        clearWebSocketOnLogin(client?.subscriptionClient);
      } else {
        setTimeout(() => {
          // @ts-ignore
          clearWebSocketOnLogin(client?.subscriptionClient);
        }, 2000);
      }
    },
    [accountId]
  );

  return !protectedRoute || isLogged ? (
    children
  ) : (
    <LoadingRouteContainer>
      <Loading size="xl" />
    </LoadingRouteContainer>
  );
};

export default RequireAuth;
