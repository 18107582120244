import React from "react";
import { FilterCellButton } from "../FilterCellButton";
import { FilterCellsContainer } from "./styled-components";
import type { GroupedFilterCellsProps } from "./types";

export const GroupedFilterCells = ({
  filterOptions,
  size,
  onChange,
  activeValue = "",
  isStretched = false,
  qaLabel = "filter-cells",
  ...rest
}: GroupedFilterCellsProps) => (
  <FilterCellsContainer
    size={size}
    isStretched={isStretched}
    data-qa-label={qaLabel}
    {...rest}
  >
    {filterOptions.map(({ value, ...props }) => (
      <FilterCellButton
        key={value}
        value={value}
        size={size}
        onChange={onChange}
        isSelected={activeValue === value}
        qaLabel={`filter-cell-button-${value}`}
        isStretched={isStretched}
        {...props}
      />
    ))}
  </FilterCellsContainer>
);

export type { GroupedFilterCellsProps };
