import styled, { css } from "styled-components";

export const LinkCardButton = styled.button<{
  isLinkItem: boolean;
  isGrouped: boolean;
}>`
  width: 100%;
  position: relative;
  background-color: var(--fd-colors-background-surface);
  padding: var(--fd-space-space-4) var(--fd-space-space-3);

  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background-color: var(--fd-colors-background-hover);
    }
  }

  &:disabled,
  &:disabled p,
  &:disabled h3 {
    color: var(--fd-colors-content-disabled);
  }

  &:active,
  &:active p,
  &:active h3 {
    color: var(--fd-colors-content-on-dark);
    background-color: var(
      --fd-colors-component-button-tertiary-background-active
    );
  }

  ${({ isLinkItem }) =>
    isLinkItem
      ? css`
          &:not(:last-child) {
            &::before {
              content: "";
              position: absolute;
              height: 1px;
              bottom: 0;
              background: var(--fd-colors-border-subtle);
              width: 100%;
              right: 0;
            }
          }
        `
      : css`
          border-radius: var(--fd-radii-border-radius-020);
          border: 1px solid var(--fd-colors-border-default);
        `}

  ${({ isGrouped }) =>
    isGrouped &&
    css`
      border: none;
      border-bottom: 1px solid var(--fd-colors-border-default);
    `}

  &:first-child {
    border-top-right-radius: var(--fd-radii-border-radius-020);
    border-top-left-radius: var(--fd-radii-border-radius-020);
  }

  &:last-child {
    border-bottom-right-radius: var(--fd-radii-border-radius-020);
    border-bottom-left-radius: var(--fd-radii-border-radius-020);
    border: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LeftSideContainer = styled.div<{
  hasEndContent: boolean;
  alignStart?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ alignStart }) =>
    alignStart &&
    css`
      > div:first-of-type {
        align-self: start;
      }
    `}

  ${({ hasEndContent }) =>
    hasEndContent &&
    css`
      max-width: 90%;
    `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: baseline;

  p,
  h3 {
    text-align: left;
  }
`;
