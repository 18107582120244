import type { MutableRefObject } from "react";
// @ts-ignore
import { logoutUser } from "@fdr/shared-actions/UserActions";
import {
  clearAuthTokenCookie,
  clearLoginTokenCookie,
  getLogoutKeepPage,
  setLogoutKeepPage
} from "@tvg/sh-utils/sessionUtils";
import { isEmpty } from "lodash";
import { resetGeoLocation } from "@tvg/sh-geolocation/src/utils/requestGeolocation";
import { geolocationStatusClear } from "@tvg/sh-geolocation/src/redux/actions";
import { resetProgramPageState } from "@tvg/pp/src/store/actions";
import { Dispatch } from "redux";
import { Location, NavigateFunction } from "react-router-dom";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import CPPService from "@tvg/sh-cpp";
import { storeCPPPromotions } from "@tvg/sh-cpp/src/service/redux/actions";

import mediatorScoped from "../../../../../../../utils/mediator";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import { getUMEvent } from "../../../../../../../utils/mediator/events";
import { userInitialData } from "./helpers/userData";
import type { ExtraProps } from "./types";

export const simpleLogout = (dispatch: Dispatch) => {
  dispatch(logoutUser());
  clearAuthTokenCookie();
  clearLoginTokenCookie();
};

export const logoutSubscription = (
  dispatch: Dispatch,
  navigate: NavigateFunction,
  location: Location,
  extraProps: MutableRefObject<ExtraProps>
) => {
  const event = getUMEvent(UMEvents.Logout);
  const cppService = new CPPService();
  mediatorScoped.subscribe(event.completion, () => {
    const { geolocationError, enableCPPPromos } = extraProps?.current;

    simpleLogout(dispatch);

    if (!isEmpty(geolocationError)) {
      dispatch(resetGeoLocation());
    }
    dispatch(geolocationStatusClear());
    dispatch(resetProgramPageState());

    if (enableCPPPromos) {
      cppService.getPromos().then((cppPromos) =>
        // @ts-ignore
        dispatch(storeCPPPromotions(cppPromos || []))
      );
    }

    mediatorClassic.dispatch("TVG_LOGIN:USER_SESSION_UPDATE", {
      ...userInitialData
    });

    if (typeof window !== "undefined") {
      window.sessionStorage.removeItem("userId");
    }

    const logoutKeepPage = getLogoutKeepPage();
    const isWagerpad = location.pathname.includes("/wagerpad");

    if (!isWagerpad && !logoutKeepPage) {
      navigate("/");
    }

    setLogoutKeepPage(false);
  });
};
