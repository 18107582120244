import React from "react";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { Modal } from "@tvg/design-system/web";
import { Body, Description, Section, Title } from "./styled-components";

interface SectionInfo {
  title: string;
  description: string;
}

interface PlayableBalanceContent {
  title: string;
  description: string;
  sections: SectionInfo[];
}

interface Props {
  onClose: NullaryFn<void>;
  isOpen: boolean;
  modalContent: PlayableBalanceContent;
}

const PlayableBalanceModal = (props: Props) => (
  <Modal
    isOpen={props.isOpen}
    headerProps={{ title: props.modalContent.title, showCloseButton: true }}
    onClose={props.onClose}
    padding={0}
    maxWidth="450px"
  >
    <Body>
      <Description>{props.modalContent.description}</Description>
      {props.modalContent.sections.map((section) => (
        <Section key={section.title}>
          <Title>{section.title}</Title>
          <Description>{section.description}</Description>
        </Section>
      ))}
    </Body>
  </Modal>
);

export default PlayableBalanceModal;
