import { isXSell } from "@tvg/sh-utils/mobileUtils";
import {
  getAuthTokenCookie,
  getUserSessionData
} from "@tvg/sh-utils/sessionUtils";
import { Dispatch } from "redux";
import { get } from "lodash";
import type { MutableRefObject } from "react";
import type { Session, User } from "@fanduel/account-contract";
import mediatorScoped from "../../../../../../../utils/mediator";
import { UMEvents } from "../../../../../../../utils/mediator/types";
import { getUMEvent } from "../../../../../../../utils/mediator/events";
import {
  ExtraProps,
  SessionUpdatePayload,
  SessionUpdateStandalone,
  SessionUpdateXSell
} from "./types";
import { completeLogin } from "../../../services/completeLogin";

const mapSession = (
  rawSession: SessionUpdateStandalone | SessionUpdateXSell
) => {
  if (isXSell()) {
    const authToken: string = get(rawSession, "authToken");

    if (!authToken) {
      return {};
    }

    const accountUser = getUserSessionData(authToken);

    return {
      session: {
        token: authToken,
        sessionId: accountUser.sessionId,
        expires: accountUser.sessionExpireDate
      } as unknown as Session,
      accountUser: {
        userId: accountUser.fdUserId,
        emailAddress: accountUser.userEmail,
        username: accountUser.userName
      } as unknown as User
    };
  }

  const session: Session = get(rawSession, "session");
  const accountUser: User = get(rawSession, "user");

  return {
    session,
    accountUser
  };
};

export const sessionUpdateSubscribe = (
  dispatch: Dispatch,
  extraProps: MutableRefObject<Partial<ExtraProps>>
) => {
  const sessionUpdateEvent = getUMEvent(UMEvents.SessionUpdate);
  const logoutEvent = getUMEvent(UMEvents.Logout);

  mediatorScoped.subscribe(
    sessionUpdateEvent.completion,
    async (data: SessionUpdatePayload) => {
      const { session, accountUser } = mapSession(data.payload);

      if (!session || !accountUser) {
        if (getAuthTokenCookie()) {
          mediatorScoped.dispatch({
            type: logoutEvent.completion
          });
        }

        return;
      }

      completeLogin({
        accountUser,
        session,
        dispatch,
        enforceAcceptTerms: extraProps.current.enforceAcceptTerms,
        userFavoriteTracksLoaded: extraProps.current.userFavoriteTracksLoaded,
        enableCPPPromos: extraProps.current.enableCPPPromos
      });
    }
  );
};
