import Poller from "@tvg/poller";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getIsLogged } from "@urp/store-selectors";

import { getBalancePoller } from "../../../../redux/selector/wallet";
import { getPollerTime } from "./services/getPollerTime";
import { setBalancePollerTimer } from "../../../../redux/actions/wallet";
import { getBalance } from "../../../../utils/wallet/getBalance";

const BalancePoller = new Poller();
const BalancePollerTimer = new Poller();

export const useBalanceByPoller = () => {
  const dispatch = useDispatch();
  const balancePollerInterval = useSelector(getBalancePoller); // timer in Seconds
  const isLogged = useSelector(getIsLogged);

  useEffect(() => {
    if (!BalancePollerTimer.isRunning()) {
      BalancePollerTimer.start(async () => {
        const pollerTimer = await getPollerTime();
        dispatch(setBalancePollerTimer(pollerTimer));
      }, 300000);
    }

    return () => {
      BalancePollerTimer.stop();
    };
  }, []);

  useEffect(() => {
    if (!isLogged && BalancePoller.isRunning()) {
      BalancePoller.stop();
    } else if (isLogged) {
      BalancePoller.start(() => {
        getBalance(dispatch);
      }, balancePollerInterval * 1000);
    }
  }, [isLogged, balancePollerInterval]);
};
