import type { MutableRefObject } from "react";
import { Dispatch } from "redux";
import mediator from "@tvg/mediator";
import {
  checkValidWageringState,
  createSportsbookRegionCookie
} from "@tvg/sh-utils/sessionUtils";
import { setGeoLocationState } from "@tvg/sh-geolocation/src/utils/requestGeolocation";
import { setUserLoggedState } from "@fdr/shared-actions/UserActions";

import { ExtraProps, LaunchInRegionAction } from "./types";
import { completeLogin } from "../../../services/completeLogin";
import { formatAuthDetail } from "../../../../../../../utils/account/formatAuthDetail";

export const launchInRegionSubscribe = (
  dispatch: Dispatch,
  extraProps: MutableRefObject<Partial<ExtraProps>>
) => {
  mediator.ios.subscribe(
    "x-sell/bridge/to-native/launchInRegion",
    (data: LaunchInRegionAction) => {
      const { sportsbookRegion, authToken } = data.payload;
      const { allowedStates } = extraProps.current;
      const isUserStateAllowedBets = checkValidWageringState(
        sportsbookRegion,
        allowedStates || []
      );

      if (isUserStateAllowedBets) {
        createSportsbookRegionCookie(sportsbookRegion);
        dispatch(setGeoLocationState(sportsbookRegion));

        const { user, session } = formatAuthDetail({
          authToken
        });

        if (user && session) {
          completeLogin({
            accountUser: user,
            session,
            dispatch,
            enforceAcceptTerms: true
          });
        } else {
          dispatch(setUserLoggedState(false));
        }
      }
    }
  );
};
