import React from "react";
import { Modal } from "@tvg/design-system/web";
import { Props } from "./types";
import { QA_LABELS } from "../../utils/constants";

const ViewMobile = ({ isModalOpen, title, onClose, renderContent }: Props) => (
  <Modal
    isOpen={isModalOpen}
    headerProps={{ title, showCloseButton: true }}
    onClose={onClose}
    qaLabel={QA_LABELS.modal.container}
    padding={0}
    hasMaxHeight
  >
    {renderContent}
  </Modal>
);

export default ViewMobile;
