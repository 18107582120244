import type { Dispatch } from "redux";
import profileService, {
  FDR_USER_NOT_VERIFIED_EXCEPTION,
  INELIGIBLE_RESIDENCY_STATE_FOR_RACING_MESSAGE,
  TVG_ACCOUNT_ID_NOT_FOUND_EXCEPTION
} from "@tvg/api/uam";
import { get } from "lodash";
// @ts-ignore
import { setUserData } from "@fdr/shared-actions/UserActions";
import {
  fdrGhostAccountExceptionAction,
  resetStateLocationSplashAction
} from "@urp/location-splash/src/actions";
import { isXSell } from "@tvg/sh-utils/mobileUtils";
import type { User } from "@fanduel/account-contract";
import {
  UserProfile,
  UserInfo,
  GetUserProfile
} from "../../../../../../types/account/user";

const getUserInfo = (
  accountUser: User,
  userProfile?: UserProfile
): UserInfo => ({
  userName: accountUser.username,
  emailAddress: accountUser.email,
  profile: get(userProfile, "wagerProfile", "FDR-Generic"),
  accountNumber: String(get(userProfile, "accountNumber", "")),
  firstName: accountUser.firstName,
  lastName: accountUser.lastName,
  homeAddress: { state: get(userProfile, "state", "") },
  fdUserId: accountUser.userId,
  hasMadePreviousWager: get(userProfile, "hasWagered", null),
  fullAvatarUrl: accountUser.fullAvatarUrl
});

export const getUserProfile = async (
  accountUser: User,
  stateAbbr: string,
  dispatch: Dispatch
): Promise<GetUserProfile> => {
  if (accountUser) {
    try {
      const { data: userProfile } =
        (await profileService.getFDRUserProfile()) as unknown as {
          data: UserProfile;
        };
      const isVerified = get(userProfile, "isUserVerified", false) as boolean;

      if (
        (typeof userProfile.unmetCriteria === "string" ||
          Array.isArray(userProfile.unmetCriteria)) &&
        userProfile.unmetCriteria.includes(
          INELIGIBLE_RESIDENCY_STATE_FOR_RACING_MESSAGE
        )
      ) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw INELIGIBLE_RESIDENCY_STATE_FOR_RACING_MESSAGE;
      }

      const user = getUserInfo(accountUser, userProfile);

      return { user, isVerified };
    } catch (error) {
      if (
        get(error, "response.data.code") ===
          TVG_ACCOUNT_ID_NOT_FOUND_EXCEPTION &&
        stateAbbr
      ) {
        try {
          await profileService.postFDRProfileMigration(
            accountUser.userId,
            stateAbbr,
            accountUser.email
          );

          if (typeof dispatch === "function") {
            dispatch(resetStateLocationSplashAction());
          }

          return getUserProfile(accountUser, stateAbbr, dispatch);
        } catch (migrationError) {
          const errorCode = get(migrationError, "response.data.code");
          const errorMessage = get(migrationError, "response.data.message");
          if (
            (typeof errorMessage === "string" || Array.isArray(errorMessage)) &&
            errorMessage.includes(INELIGIBLE_RESIDENCY_STATE_FOR_RACING_MESSAGE)
          ) {
            // eslint-disable-next-line
            throw INELIGIBLE_RESIDENCY_STATE_FOR_RACING_MESSAGE;
          }

          if (errorCode === FDR_USER_NOT_VERIFIED_EXCEPTION) {
            const userFormatted = getUserInfo(accountUser);
            dispatch(
              setUserData({
                // @ts-ignore -> We need to normalize some fields, but the type is the same.
                user: userFormatted
              })
            );

            return { user: userFormatted as UserInfo, isVerified: false };
          }

          dispatch(fdrGhostAccountExceptionAction());

          if (isXSell()) {
            throw error;
          }

          return {
            user: null,
            isVerified: false
          };
        }
      } else {
        throw error;
      }
    }
  } else {
    return {
      user: null,
      isVerified: false
    };
  }
};
