import React from "react";
import { RaceStatusEnum } from "@tvg/ts-types/Race";
import type { RaceVideo } from "@tvg/ts-types/Race";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { Icon } from "../Icon";
import { TvBrand } from "../TvBrand";
import type { IconConfig } from "./types";
import { ICONS } from "../../utils/constants";

// if race is not “Result Official” and is available in FDTV, then FDTV is displayed
// if race is not “Result Official” and is available in FDRTV, the FDRTV is displayed
// if race is “Result Official” and a Replay is available, then Replay Icon is displayed
// if race is “Result Official” and no Replay is available, the No Replay Icon is displayed

export const renderVideoIcons = (
  video: RaceVideo | undefined,
  statusCode: RaceStatusEnum,
  lineColor: string,
  brandColor: "grey" | "white",
  qaLabel = ""
) => {
  if (!video) return null;

  const icons = [];

  if (video.hasReplay && statusCode === RaceStatusEnum.RACE_OFFICIAL)
    icons.push(
      <Icon
        name="replay"
        key="replay"
        size="s"
        lineColor={lineColor}
        qaLabel={buildQaLabel([qaLabel, ICONS, "replay"])}
      />
    );

  if (!video.hasReplay && statusCode === RaceStatusEnum.RACE_OFFICIAL)
    icons.push(
      <Icon
        name="noVideo"
        key="noVideo"
        size="s"
        lineColor={lineColor}
        qaLabel={buildQaLabel([qaLabel, ICONS, "noVideo"])}
      />
    );

  if (video.liveStreaming)
    icons.push(
      <Icon
        name="liveVideo"
        key="liveVideo"
        size="s"
        lineColor={lineColor}
        qaLabel={buildQaLabel([qaLabel, ICONS, "liveVideo"])}
      />
    );

  if (
    statusCode !== RaceStatusEnum.RACE_OFFICIAL && // TODO: Add condition for FDRTV when logo is available
    (video.onTvg || video.onTvg2)
  )
    icons.push(
      <TvBrand
        key="tvBrand"
        height={16}
        brandColor={brandColor}
        qaLabel={buildQaLabel([qaLabel, ICONS, "logo"])}
      />
    );

  return icons;
};

export const renderOtherIcons = (
  isLogged: boolean,
  isOptedIn: boolean,
  hasPromoAvailable: boolean,
  hasActiveBets: boolean,
  lineColor: string,
  promoLineColor: string,
  promoBackgroundColor: string,
  qaLabel = ""
) => {
  const render: IconConfig[] = [];

  // If user is Logged In and Opted In in the Race, then Opted-In Icon is displayed
  if (isLogged && isOptedIn) {
    render.push({
      name: "optedIn"
    });
  }

  // If user is Logged Out OR not Opted In and there’s a promo available for the Race, then Promo Icon is displayed
  if ((!isLogged || !isOptedIn) && hasPromoAvailable) {
    render.push({
      name: "promo"
    });
  }

  // If user is Logged In and has any active bet in the Race, then Bet Icon is displayed
  if (isLogged && hasActiveBets) {
    render.push({
      name: "bets"
    });
  }

  return render.map((config, index) => (
    <Icon
      key={`${config.name}-${index.toString()}`}
      qaLabel={buildQaLabel([qaLabel, ICONS, config.name])}
      name={config.name}
      size="s"
      lineColor={hasPromoAvailable ? promoLineColor : lineColor}
      backgroundColor={hasPromoAvailable ? promoBackgroundColor : "transparent"}
    />
  ));
};
