import React, { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { get } from "lodash";
import {
  ToastMessage,
  useTheme,
  breakpoints,
  useMediaQuery
} from "@tvg/design-system";
import { Toast } from "@tvg/design-system/web";
import { getIsBetSlipExpanded } from "@tvg/pp/src/store/selectors";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
// @ts-ignore
import { usePrevious } from "@tvg/custom-hooks";
import { clearToasts } from "@tvg/pp/src/store/actions";
import { ToastContainer } from "./styled-components";
import { ToastMessageStore } from "./types";

interface Props {
  isLhnVisible: boolean;
}

const ToastManager: React.FC<Props> = ({ isLhnVisible }) => {
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);
  const currentToasts: ToastMessageStore[] = useSelector((state) =>
    get(state, "toast", [])
  );
  const theme = useTheme();
  const location = useLocation();
  const previousLocation = usePrevious(location);
  const dispatch = useDispatch();
  const isBetSlipCollapsed = useSelector(getIsBetSlipExpanded);

  const isUserLogged: boolean = useSelector((state) =>
    get(state, "userData.logged", false)
  );

  const betSlipHeight: number = useSelector((state) =>
    get(state, "programPage.betSlipHeight", false)
  );

  useEffect(() => {
    if (
      currentToasts.length > 0 &&
      (location?.pathname !== previousLocation?.pathname ||
        location?.search !== previousLocation?.search)
    ) {
      dispatch(clearToasts());
    }
  }, [location, previousLocation, currentToasts.length]);

  const isBetSlipOpened: boolean = useSelector((state) =>
    get(state, "BetTicket.betSlipOpen", false)
  );

  const toastsToRender = useMemo(() => {
    if (currentToasts) {
      return currentToasts.map((toastProps) => {
        const { id, ...toastMessageProps } = toastProps;
        return (
          <>
            {/* TODO: Tech debt - we need to migrate this to the new Toast component */}
            {!id.includes("new-toast") ? (
              <ToastMessage
                {...toastMessageProps}
                key={id}
                hasRoundedStyle={isMobile}
              />
            ) : (
              <Toast
                key={id}
                qaLabel={toastMessageProps.qaLabel}
                showToast={toastMessageProps.isVisible}
                duration={toastMessageProps.duration}
                title={toastMessageProps.message}
                toastTrigger={Date.now()}
                type={isMobile ? "round" : "straight"}
                onDismissPress={toastMessageProps.onHide}
              />
            )}
          </>
        );
      });
    }

    return null;
  }, [JSON.stringify(currentToasts)]);

  let portalRoot;

  if (typeof document !== "undefined") {
    portalRoot = document.body;
  }

  if (!portalRoot) {
    return null;
  }

  return createPortal(
    <ToastContainer
      theme={theme}
      betSlipOpened={isBetSlipOpened}
      isLhnVisible={isLhnVisible}
      isBetSlipCollapsed={isBetSlipCollapsed}
      betSlipHeight={betSlipHeight}
      isUserLogged={isUserLogged}
    >
      {toastsToRender}
    </ToastContainer>,
    portalRoot
  );
};

export default ToastManager;
