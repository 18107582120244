import { UnifiedModules } from "../../hooks/useLoadModule/types";
import { SetModuleInitializedAction } from "./types";

export const setModuleInitializedAction = (
  module: UnifiedModules
): SetModuleInitializedAction => ({
  type: "SET_UM_INITIALIZED",
  payload: {
    module
  }
});
