import type { MutableRefObject } from "react";
import type { Dispatch } from "redux";
import type { NavigateFunction, Location } from "react-router-dom";

import { homepageSubscription } from "./homepage";
import { logoutSubscription } from "./logout";
import { sessionUpdateSubscribe } from "./sessionUpdate";
import type { ExtraProps } from "./types";
import { acceptTermsSubscription } from "./acceptTerms";
import { verifyUserSubscription } from "./verifyUser";
import { hotReloadSubscription } from "./hotReload";

export const accountSubscriptions = (
  dispatch: Dispatch,
  navigate: NavigateFunction,
  location: Location,
  extraProps: MutableRefObject<ExtraProps>
) => {
  acceptTermsSubscription(dispatch);
  homepageSubscription(dispatch, extraProps);
  logoutSubscription(dispatch, navigate, location, extraProps);
  sessionUpdateSubscribe(dispatch, extraProps);
  verifyUserSubscription(dispatch);
  hotReloadSubscription(extraProps);
};
