import styled from "styled-components";
import { fontSansSerif } from "@tvg/atomic-ui/_static/Typography";

export const AccountBalancesSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 0 16px;
  width: 100%;
`;

export const BalanceValue = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 700;
  line-height: 110%;
  font-size: 40px;
  color: ${({ theme }) => theme.colorTokens.content.onDark};
  align-items: center;
  justify-content: center;
  font-family: ${fontSansSerif};
`;

// toggle visibility with display none instead of conditionally rendering
// to prevent pressable state to bubble to other button
export const VisibilityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colorTokens.content.onDark};
  font-size: 14px;
  font-weight: 700;
  font-family: ${fontSansSerif};
  line-height: 125%;
  cursor: pointer;
`;

export const VisibilityLabel = styled.span`
  margin-left: 4px;
`;

export const BalanceDetailsHeader = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: row;
  ${({ isVisible }) => isVisible && "margin-bottom: 12px"};
`;

export const BalanceDetailsTitle = styled.span`
  font-size: 16px;
  font-family: ${fontSansSerif};
  line-height: 115%;
  color: ${({ theme }) => theme.colorTokens.content.onDark};
  font-weight: 700;

  &::after {
    content: "|";
    margin-left: 8px;
    margin-right: 12px;
    font-weight: 400;
  }
`;

export const ShowDetails = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colorTokens.content.onDark};
  margin-left: 8px;
`;

export const PlaceHolder = styled.div`
  width: 100%;
  height: 16px;
`;

export const BalanceHidden = styled.div`
  width: 96px;
  height: 2px;
  background: ${({ theme }) => theme.colorTokens.content.onDark};
  margin: 26px 0 22px;
`;
