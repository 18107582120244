import React, { useContext } from "react";
import { noop } from "lodash";
import { SortContext } from "../../context";
import { OptionButton, StyledTextTitle } from "./styled-components";
import type { OptionProps } from "./types";

export type { OptionProps };

export const Option = ({
  label,
  value,
  isSelected = false,
  isDisabled = false,
  hasBorderBottom = false,
  qaLabel = value
}: OptionProps) => {
  const { selectedValue, onChange = noop } = useContext(SortContext);

  const isSelectedValue = isSelected || selectedValue === value;

  const pressHandler = () => {
    onChange(value);
  };

  return (
    <OptionButton
      onClick={pressHandler}
      disabled={isDisabled}
      aria-selected={isSelectedValue}
      data-qa-label={qaLabel}
      hasBorderBottom={hasBorderBottom}
    >
      <>
        <StyledTextTitle
          aria-selected={isSelectedValue}
          aria-disabled={isDisabled}
        >
          {label}
        </StyledTextTitle>
      </>
    </OptionButton>
  );
};
