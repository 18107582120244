import { webm, mp4 } from "./media";

// Detect iOS browsers < version 10
const oldIOS = (): boolean => {
  if (typeof navigator !== "undefined") {
    const result =
      (/CPU.*OS ([0-9_]{3,4})[0-9_]{0,1}|(CPU like).*AppleWebKit.*Mobile/i.exec(
        navigator.userAgent
      ) || [0, ""])[1]
        .replace("undefined", "3_2")
        .replace("_", ".")
        .replace("_", "");
    // @ts-ignore
    return parseFloat(result) < 10 && !window.MSStream;
  }
  return false;
};

const nativeWakeLock = (): boolean => "wakeLock" in navigator;

class NoSleep {
  private enabled: boolean;

  private wakeLockRef: WakeLockSentinel | null = null;

  private noSleepTimer: number | null = null;

  private noSleepVideo?: HTMLVideoElement;

  constructor() {
    this.enabled = false;
    // If the browser supports the native Wake Lock API, use it.
    if (nativeWakeLock() && navigator.wakeLock) {
      this.wakeLockRef = null;
      const handleVisibilityChange = (): void => {
        if (
          this.wakeLockRef !== null &&
          document.visibilityState === "visible"
        ) {
          this.enable();
        }
      };
      document.addEventListener("visibilitychange", handleVisibilityChange);
      document.addEventListener("fullscreenchange", handleVisibilityChange);
    } else if (oldIOS()) {
      // For older iOS devices, set up an interval (noSleepTimer)
      this.noSleepTimer = null;
    } else {
      // For browsers that lack the Wake Lock API, set up a no-sleep video element.
      this.noSleepVideo = document.createElement("video");
      this.noSleepVideo.setAttribute("title", "No Sleep");
      this.noSleepVideo.setAttribute("playsinline", "");

      NoSleep.addSourceToVideo(this.noSleepVideo, "webm", webm);
      NoSleep.addSourceToVideo(this.noSleepVideo, "mp4", mp4);

      this.noSleepVideo.addEventListener("loadedmetadata", () => {
        if (this.noSleepVideo) {
          if (this.noSleepVideo.duration <= 1) {
            // For webm source, use the loop attribute.
            this.noSleepVideo.setAttribute("loop", "");
          } else {
            // For mp4 source, periodically change currentTime.
            this.noSleepVideo.addEventListener("timeupdate", () => {
              if (this.noSleepVideo && this.noSleepVideo.currentTime > 0.5) {
                this.noSleepVideo.currentTime = Math.random();
              }
            });
          }
        }
      });
    }
  }

  private static addSourceToVideo(
    element: HTMLVideoElement,
    type: string,
    dataURI: string
  ): void {
    const source = document.createElement("source");
    source.src = dataURI;
    source.type = `video/${type}`;
    element.appendChild(source);
  }

  public get isEnabled(): boolean {
    return this.enabled;
  }

  public async enable(): Promise<void> {
    if (nativeWakeLock() && navigator.wakeLock) {
      try {
        this.wakeLockRef = await navigator.wakeLock.request("screen");
        this.enabled = true;
        this.wakeLockRef.addEventListener("release", () => {});
      } catch (err: unknown) {
        this.enabled = false;
        throw err;
      }
    } else if (oldIOS()) {
      this.disable();
      this.noSleepTimer = window.setInterval(() => {
        if (!document.hidden) {
          window.location.href = window.location.href.split("#")[0];
          window.setTimeout(window.stop, 0);
        }
      }, 15000);
      this.enabled = true;
    } else {
      try {
        if (this.noSleepVideo) {
          await this.noSleepVideo.play();
          this.enabled = true;
        } else {
          throw new Error("No sleep video element is not initialized.");
        }
      } catch (err: unknown) {
        this.enabled = false;
        throw err;
      }
    }
  }

  public disable(): void {
    if (nativeWakeLock() && this.wakeLockRef) {
      this.wakeLockRef.release();
      this.wakeLockRef = null;
    } else if (oldIOS()) {
      if (this.noSleepTimer) {
        window.clearInterval(this.noSleepTimer);
        this.noSleepTimer = null;
      }
      if (this.noSleepVideo) {
        this.noSleepVideo.pause();
      }
      this.enabled = false;
    }
  }
}

export { webm, mp4, NoSleep };
