import { ToastMessageStore } from "../types";

export type AddToast = {
  type: "ADD_TOAST";
  payload: {
    toastProps: ToastMessageStore;
  };
};

export type RemoveToast = {
  type: "REMOVE_TOAST";
  payload: {
    toastId: string;
  };
};

export type ClearToasts = {
  type: "CLEAR_TOASTS";
};

export type ToastActions = AddToast | RemoveToast | ClearToasts;

export const addToast = (toastProps: ToastMessageStore): AddToast => ({
  type: "ADD_TOAST",
  payload: {
    toastProps
  }
});

export const removeToast = (toastId: string): RemoveToast => ({
  type: "REMOVE_TOAST",
  payload: { toastId }
});
