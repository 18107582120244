import type { Dispatch } from "redux";
import { Unsubscribe, User } from "@fanduel/account-contract";
import {
  getSportsbookRegionCookie,
  setLogoutKeepPage
} from "@tvg/sh-utils/sessionUtils";

import { AccountModule } from "../../../../../../../useLoadModule/types";
import { getUMEvent } from "../../../../../../../../utils/mediator/events";
import { UMEvents } from "../../../../../../../../utils/mediator/types";
import mediatorScoped from "../../../../../../../../utils/mediator";
import { getSession, logout } from "../../../../../../../../utils/account";
import { simpleLogout } from "../../../../utils/completion/logout";

export const webSessionUpdateSubscribe = async (
  accountInstance: AccountModule,
  coreConfigState: string | undefined,
  dispatch: Dispatch
): Promise<Unsubscribe | undefined> => {
  if (!coreConfigState) {
    if (!getSportsbookRegionCookie()) {
      simpleLogout(dispatch);
    }
    return undefined;
  }

  const event = getUMEvent(UMEvents.SessionUpdate);

  const unsubscribe = await accountInstance.subscribeSession(
    async (session) => {
      let user: User | undefined;

      if (session) {
        user = await accountInstance.getUserAsync("Silent");
      }

      mediatorScoped.dispatch({
        type: event.completion,
        payload: {
          user,
          session
        }
      });
    }
  );

  const isLogged = await accountInstance.checkValidSession();

  if (!isLogged) {
    setLogoutKeepPage(true);
    logout();
  } else {
    // This only needed to edge cases when the /session request fail
    getSession("Silent");
  }

  return unsubscribe;
};
