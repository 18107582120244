import React, {
  memo,
  forwardRef,
  useState,
  useEffect,
  ChangeEvent
} from "react";
import type { InputSelectProps } from "../types";
import {
  InputWrapper,
  TopWrapper,
  InputBox,
  SelectIconWrapper
} from "../styled-components";
import { InputLabel } from "../inputLabel";
import { InputMessage } from "../inputMessage";
import { Icon } from "../../Icon";
import { BoxWrapper, Select, Option } from "./styled-components";

export const InputSelect = forwardRef<HTMLSelectElement, InputSelectProps>(
  (
    {
      placeholder,
      label = "",
      messageAbove = "",
      messageBellow = "",
      isInvalid = false,
      isOptional = false,
      isDisabled = false,
      warningMessage = "",
      qaLabel,
      selectList,
      selectedValue = "",
      onChange,
      onChangeText,
      ...rest
    },
    // eslint-disable-next-line
    _ref
  ) => {
    const [listWithPlaceholder, setListWithPlaceholder] = useState(selectList);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
      if (placeholder) {
        setListWithPlaceholder([
          { label: placeholder, value: "" },
          ...selectList
        ]);
      } else {
        setListWithPlaceholder(selectList);
      }
    }, [selectList, placeholder]);

    return (
      <InputWrapper {...rest} data-qa-label={qaLabel}>
        <TopWrapper>
          <InputLabel label={label} isOptional={isOptional} />
        </TopWrapper>
        {!!messageAbove && (
          <InputMessage message={messageAbove} marginTop="space-3" />
        )}
        <BoxWrapper>
          <InputBox
            isInvalid={isInvalid}
            isDisabled={isDisabled}
            isFocused={isFocused}
            hasBackground
            hasBorder
            {...rest}
          >
            <Select
              disabled={isDisabled}
              value={selectedValue as string}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                if (typeof onChange === "function") {
                  const { value } = e.target;
                  // @ts-ignore
                  onChange(value);
                }
                if (typeof onChangeText === "function") {
                  const { value } = e.target;
                  onChangeText(value);
                }
              }}
              onFocus={() => setIsFocused(true)}
              onMouseOut={() => setIsFocused(false)}
              data-qa-label={`${qaLabel}-select`}
            >
              {listWithPlaceholder &&
                listWithPlaceholder.map((item) => (
                  <Option
                    key={item.value}
                    value={item.value}
                    label={item.label}
                    disabled={item.value === null || item.value === ""}
                  >
                    {item.label}
                  </Option>
                ))}
            </Select>
            <SelectIconWrapper isDisabled={isDisabled}>
              <Icon
                size="s"
                qaLabel="chevron-down-icon"
                lineColor={
                  isDisabled
                    ? `--fd-colors-component-data-input-border-disabled`
                    : `--fd-colors-component-data-input-border-active`
                }
                name="chevronDown"
              />
            </SelectIconWrapper>
          </InputBox>
        </BoxWrapper>

        {isInvalid && warningMessage && (
          <InputMessage
            message={warningMessage}
            isWarning={isInvalid}
            mx={0}
            mt={2}
            mb="space-3"
          />
        )}
        {!!messageBellow && <InputMessage message={messageBellow} />}
      </InputWrapper>
    );
  }
);

export default memo(InputSelect);
