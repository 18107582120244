import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: sticky;
  top: -1px;
  z-index: 1;
  background-color: var(--fd-colors-racing-background-default);
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
`;

export const HR = styled.hr`
  border: none;
  position: absolute;
  height: 1px;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) =>
    theme.colorTokens.lhnHeader.border.inverse};
  margin: 0;
`;
