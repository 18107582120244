import type { Store } from "redux";
import { get } from "lodash";
import type { UnifiedModules } from "../../hooks/useLoadModule/types";

const reducerRootSelector = "unifiedModules";

export const isUMInitialized =
  (module: UnifiedModules) =>
  (store: Store): boolean =>
    get(store, `${reducerRootSelector}.${module}.isInitialized`, false);
