import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { buildColor } from "@tvg/design-system";

export const Container = styled.footer`
  background-color: ${buildColor("blue_accent", "000")};
`;

export const WarningContainer = styled.div`
  padding: 12px;
  border: 1px solid var(--fd-colors-border-subtle);
`;

export const LinksContainer = styled.div<{ numberOfColumns: number }>`
  display: grid;
  grid-template-columns: repeat(
    ${({ numberOfColumns }) => numberOfColumns || 2},
    1fr
  );
  grid-gap: 20px;
  padding: 20px;

  @media print {
    display: none;
  }
`;

export const List = styled.ul<{ hasPaddingTop?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ hasPaddingTop }) =>
    hasPaddingTop &&
    css`
      padding-top: 31px;
    `}
`;

export const ListItem = styled.li`
  &:not(:last-of-type) {
    margin-bottom: 14px;
  }
`;

export const StyledLink = styled(Link)`
  color: ${buildColor("grey", "900")};
  text-decoration: none;
  cursor: pointer;
`;

export const SocialIcons = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const SocialIcon = styled.a`
  text-decoration: none;
  cursor: pointer;

  svg {
    fill: ${buildColor("grey", "700")};
  }

  &:not(:first-child) {
    margin-left: 20px;
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 12px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 12px;
    left: 12px;
    height: 2px;
    background-color: ${buildColor("blue_accent", "200")};
  }
`;
