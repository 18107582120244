import React from "react";
import {
  LabelWrapper,
  IconWrapper,
  StyledLabel,
  StyledOptionalTag
} from "./styled-components";
import { InputLabelProps } from "./types";
import { Icon } from "../Icon";

export const InputLabel = ({
  label,
  isOptional = false,
  isChecked = false,
  qaLabel = "label",
  ...rest
}: InputLabelProps) => (
  <LabelWrapper>
    <StyledLabel {...rest} data-qa-label={qaLabel}>
      {label}
    </StyledLabel>
    {isOptional && <StyledOptionalTag>(optional)</StyledOptionalTag>}
    {isChecked && (
      <IconWrapper>
        <Icon
          name="success"
          size="s"
          backgroundColor="--fd-colors-link-default-base"
        />
      </IconWrapper>
    )}
  </LabelWrapper>
);
